
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceObject } from '@/models/jsonapi'
  import { Contract } from "@/models/contracts";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { sellerContractIndexColumns } from '@/view_scripts/table_columns/contract_columns'

  export default defineComponent({
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed<number>(() => root.$store.state.contracts.pagination.totalItems)
      const getItems = async(ctx: BvTableCtxObject) => {
        const contractQueryParams = {
          // 'filter[archived]': false,
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-createdAt',
          'include': 'agency,household'
        }
        await root.$store.dispatch('contracts/load', contractQueryParams)
        const contractResponse = root.$store.state.contracts.response
        const hhIds = extractRelationshipIds(contractResponse, 'household')
        const crQueryParams = {
          'filter[household_id]':hhIds.join(','),
          'page[size]': hhIds.length*2,
          'include': 'address'
        }
        await root.$store.dispatch('care_receivers/load', crQueryParams)
        const crResponse = root.$store.state.care_receivers.response
        contractResponse.data.forEach((c) => {
          const householdAttributes = c.attributes?.household?.attributes
          if (householdAttributes) {
            householdAttributes.care_receivers = crResponse.data.filter((cr)=>{
              const crHouseholdRelationship = cr.relationships?.household as RelationshipsWithData
              const crHouseholdRelationshipData = crHouseholdRelationship?.data as ResourceObject
              return c.attributes?.household?.id === crHouseholdRelationshipData?.id
            })
          }
        })
        return contractResponse.data
      }

      const columns = ref(sellerContractIndexColumns)

      return {
        getItems,
        totalItems,
        columns
      }
    }
  })
